<template>
  <v-row>
    <v-col
      cols="12"
    >
      <base-card>
        <v-card-title>Praxisnoten</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Praktikant
                  </th>
                  <th class="text-left">
                    Note 1
                  </th>
                  <th class="text-left">
                    Note 2
                  </th>
                  <th class="text-left">
                    Note 3
                  </th>
                  <th class="text-left">
                    Note 4
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in exams"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.mark1 }}</td>
                  <td>{{ item.mark2 }}</td>
                  <td>{{ item.mark3 }}</td>
                  <td>
                    <v-col
                      cols="2"
                    >
                      <v-text-field
                        dense
                        label="Note"
                      />
                    </v-col>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  export default {

    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'InternMarks',
    },
    data () {
      return {
        exams: [
          {
            name: 'John Doe',
            mark1: 6.0,
            mark2: 6.0,
            mark3: 6.0,
            mark4: 6.0,
          },
          {
            name: 'Jane Doe',
            mark1: 6.0,
            mark2: 5.5,
            mark3: 6.0,
            mark4: 6.0,
          },
          {
            name: 'Hans Muster',
            mark1: 6.0,
            mark2: 5.5,
            mark3: 6.0,
            mark4: 6.0,
          },
        ],
      }
    },
  }
</script>
