<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <children />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <intern-marks />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import Children from '@/views/app/apps/Children'
  import InternMarks from '@/views/app/apps/InternMarks'
  export default {
    name: 'Parent',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Parent',
    },
    components: {
      InternMarks: InternMarks,
      Children: Children,
    },
    data () {
      return {
      }
    },
    methods: {
    },
  }
</script>
